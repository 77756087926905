
import {get,post} from "@/axios";


export function login(data) {
  return post('/api/login',data);
  // return new Promise(function(resolve,reject){
  //   setTimeout(function(){                              
  //     resolve({
  //       status:1,
  //       data:{
  //         role:'super'
  //       }
  //     })
  //   })
  // })
 
}

export function getUserInfo(token){//模拟接口请求权限
  return get('/api/getUserInfo',token);
 
}
export function updataPassword(token){//模拟修改密码接口
  return post('/api/updataPassword',token);
 
}
