export function setCookie(name, value, iDay) {
    let oDate = new Date();
    oDate.setDate(oDate.getDate() + iDay);
    document.cookie = name + '=' + value + ';expires=' + oDate;
}


/*
* @func getCookie
* @explain 获取cookie里的值
*/
export function getCookie(cookieName) {
   var strCookie = document.cookie;
   var arrCookie = strCookie.split("; ");
   for(var i = 0; i < arrCookie.length; i++){
       var arr = arrCookie[i].split("=");
       if(cookieName == arr[0]){
           return arr[1];
       }
   }
   return "";

}


/*
* @func removeCookie
* @explain 删除cookie里的值
*/
export function removeCookie(name) {
  setCookie(name, 1, -1);
}