<template>
      <!-- 修改密码弹窗 -->
        <div>
          <a-modal
            title="修改密码"
            :visible="editModalVisible"
            :confirm-loading="confirmLoading"
            :dialog-style="{ top: '30px' }"
            @ok="savePassword"
            @cancel="cancelPassword"
          >
          <!-- 充值表单 -->
            <a-form-model
              ref="upPasswords"
              :model="editPasswordForm"
              :rules="passwordRules"
              :label-col="{span:7}"
              :wrapper-col="{span:17}"
            >
              <a-form-model-item label="新密码" prop="password">
                <a-input v-model="editPasswordForm.password" />
              </a-form-model-item>
              <a-form-model-item label="确认密码" prop="password1">
                <a-input v-model="editPasswordForm.password1" />
              </a-form-model-item>
            </a-form-model>

          </a-modal>
        </div>

</template>
<script>
import {getCookie} from '@/static/js/cookie';
import {updateMerchants} from "@/axios/user.js"
export default {
    props: {
    editModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let validatePass3 = (rule, value, callback) => {
      if (value !== this.editPasswordForm.password) {
        callback(new Error("两次密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      confirmLoading: false,//修改密码按钮加载动效
      editPasswordForm:{
        password:'',
        password1:'',
      },
      passwordRules: {//修改密码表单验证规则
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { message: '密码长度为6-18', trigger: 'blur',min:6,max:18 },
        ],
        password1: [
          { validator:validatePass3, required: true,trigger: 'blur' },
        ],
      },
    };
  },
  created(){

  },
  methods:{
  
    savePassword() {//保存模态框
    
      this.$refs.upPasswords.validate( async valid => {

        if (valid) {

          this.confirmLoading = true;
          let id=JSON.parse(getCookie("token")).user_id
          let resule=await updateMerchants({user_id:id,...this.editPasswordForm});

          if(resule.code==200){
            console.log(resule,'改密码返回的数据')
            this.$message.success(resule.msg);
            // this.editModalVisible = false;
            this.$emit('hidemodal');
            this.confirmLoading = false;
            this.$refs.upPasswords.resetFields();
          }else{
            this.$message.error(resule.msg);
            this.confirmLoading = false;
          }

        } else {

          return false;
        }
      });
      
    },
    cancelPassword() {//取消修改密码模态框
      // this.editModalVisible = false;
      this.$refs.upPasswords.resetFields();
      this.$emit('hidemodal');
    },
  },watch:{
    
  },
};
</script>

<style lang="scss" scoped>

</style>