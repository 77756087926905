<template>
<div class="loginBg">


  <div class="loginBox_bg">
    <div class="loginBox">
      <span class="title" :data-text="projectName">{{projectName}}</span>
      <a-form-model :layout="layout" :model="form" :rules="rules" ref="ruleForm">

        <a-form-model-item prop="username">
          <a-input v-model="form.username" placeholder="请输入用户名">
            <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-model-item >
        <a-form-model-item prop="password">
          <a-input-password v-model="form.password" placeholder="请输入密码">
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item prop="rememberPassword" style="margin-bottom:0;line-height:30px;" class="rememberPassword">
          <a-checkbox v-model="form.rememberPassword" style="">
            记住密码
          </a-checkbox>
        </a-form-model-item >

        <a-form-model-item >
          <a-button type="primary" @click="onSubmit">
            登录
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>

</div>
</template>
<script>
 import {setCookie,getCookie,removeCookie} from '@/static/js/cookie';
import store from '@/store';
export default {
  data() {
    return {
      projectName:process.env.VUE_APP_ProjectName,
      layout:"horizontal",
      form: {
       rememberPassword:false,
        username: '',
        password: '',
      },
      rules: {
        username: [
          {  message: '请输入用户名', trigger: 'change',required:true },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' },
          { min: 5, message: '密码长度必须大于6', trigger: 'change' }
        ],
      },
    };
  },
  computed: {
      
  },
  created(){
    let a=getCookie('rememberPassword')
    if(a!=''){
      this.form=JSON.parse(a)
      console.log(JSON.parse(a))
    }
  },
  methods: {
     onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        
        if (valid) {
          store.dispatch('Login', { ...this.form }).then(() => { 
            if(this.form.rememberPassword){//记住密码
              setCookie('rememberPassword',JSON.stringify(this.form),7)
            }else{
              removeCookie('rememberPassword')
            }
            this.$router.push("/").catch(() =>{})
            // if(this.$route.query.url){
            //     this.$router.push(this.$route.query.url).catch(() =>{})
            // }else{
            //   this.$router.push("/").catch(() =>{})
            // }
          }).catch((err) =>{
            // this.$message.error(err);
          })

        } else {
         
          return false
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.loginBg{
  width:100%;
  height:100vh;
  background:url("../../assets/loginBg.jpg")center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: initial;
}
.loginBox_bg{
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
    top: 50%;
}
/* .loginBox_bg::before{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -10px;
  background:#fff; 
  background:url("../../assets/loginBg.jpg")center;  
  filter: blur(10px);
} */
.loginBox{
  padding:18px 12px 0;
  width:300px;
  border-radius:4px;
  background:#fff;
  position: relative;
  overflow: hidden;
}

.loginBox .title{
  margin-bottom:12px;
  font-size:1.4rem;
  text-align:center;
  width:100%;
  display: block;
  background-clip: text;
  -webkit-background-clip: text;
  color: #6b6492;
  position: relative;
}
@keyframes shine {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -190% 0;
  }
}
/* .loginBox .title::before{
  animation: shine 5s infinite linear;
  content: attr(data-text);
  background-image: linear-gradient(120deg, transparent 0%, transparent 6rem, white 11rem, transparent 11.15rem, transparent 15rem, rgba(255, 255, 255, 0.3) 20rem, transparent 25rem, transparent 27rem, rgba(255, 255, 255, 0.6) 32rem, );
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: block;
  position: absolute;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 150% 100%;
    background-repeat: no-repeat;
    animation: shine 3s infinite linear;
} */
.ant-form{
  width: 90%;
    margin: auto;
}
.ant-btn-primary{width:100%;}
.ant-form-item-control-wrapper{width:100%;margin:0;}
.ant-form-item-children{text-align:center;display:block}
.ant-form-item{margin-bottom:19px;}
.ant-form-item-with-help{margin-bottom:0 !important}



</style>
