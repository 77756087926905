
import {get,post} from "@/axios";

//用户页面
export function getUserList(data) {
    return get('/api/users/list',data);
}
export function addUser(data) {
    return post('/api/users/add',data);
}
export function editUser(data) {
    return post('/api/users/edit',data);
}

export function deleteUser(data) {
    return post('/api/users/delete',data);
}

export function updateMerchants(data) {
    return post('/api/users/modify_password',data);
}

export function getAllUser(data) {
    return get('/api/users/all',data);
}
