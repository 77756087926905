<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
     <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  name: 'app',
  data() {
    return {
      zh_CN,
    }
  },
  components: {
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height:100%;
}
.noBorder .ant-table-small{border:none !important}
.ant-table{overflow-x: auto;}
.rememberPassword .ant-form-item-control{line-height:20px;margin-bottom:4px;}
.ant-layout.ant-layout-has-sider{  height:100%;}
</style>
